import React from 'react';

const Wrapper = ({content, prefixUrl}) => {
  return (
    <div id="wrap" prefixUrl={prefixUrl}>
        {content}
    </div>
  );
};

export default Wrapper;



