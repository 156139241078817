/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import './src/styles/global.css'
import 'tw-elements'
import Wrapper from './src/components/wrapper'
export const wrapPageElement = ({ element, props }) => (
    <Wrapper {...props} content={element}/>
  );